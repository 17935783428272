<template>
  <el-container class="iot-case-container">
    <el-main class="iot-case-main">
      <router-view />
    </el-main>
    <el-aside
      width="260px"
      class="iot-case-aside public-scroll-transparent"
      :style="`background-color:${theme.SUBMENU_BACKGROUND_COLOR};`"
    >
      <help></help>
    </el-aside>
  </el-container>
</template>

<script>
import help from "../components/help";

import { mapState } from "vuex";
import {
  SUBMENU_BACKGROUND_COLOR,
  SUBMENU_TEXT_COLOR,
  SUBMENU_ACTIVE_COLOR,
} from "../util/theme";
import "@/assets/css/main.css";
export default {
  name: "Desgin",
  components: { help },
  data() {
    return {
      theme: {
        SUBMENU_BACKGROUND_COLOR,
        SUBMENU_TEXT_COLOR,
        SUBMENU_ACTIVE_COLOR,
      },
    };
  },
  computed: {
    ...mapState({
      menuTree: (state) => state.childrenMenu,
    }),
  },
  beforeMount: function () {},
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-case-container {
  height: 100%;
}
.iot-case-aside {
  height: 100%;
}
.iot-case-aside,
.iot-case-main {
  overflow: hidden;
}
.iot-system-left {
  padding: 2px;
  border-right: 1px solid #d4d4d4;
  box-shadow: 3px 0px 3px #d4d4d4;
}
.iot-menu-header {
  font-size: 12px;
  padding-left: 12px;
  line-height: 38px;
  border-bottom: 1px solid #d4d4d4;
  display: block;
  width: 100%;
  margin-bottom: 25px;
}
</style>
